
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import { CreateUsersCommand, RoleListViewModel, UpdateUsersCommand, UserViewModelDto } from "@/api-client";
import { rolesDdlGet, usersGetId, usersPost, usersPut } from "@/api/users";
import { handleInvalidForm } from "@/utils/auth";
import { Rule, ValidateFieldsError } from "@/types/async-validator";

type UserForm = {
  id?: string;
  displayName: string;
  email: string;
  roleIds: [];
  isEnabled: boolean;
  password?: string | null;
  confirmPassword?: string | null;
};

@Component({
  name: "UserDetail",
  components: {
    DialogBox,
  },
  props: {
    id: { type: String, default: "" },
    search:{ type: String },
    pageNumber: { type: String },
    pageSize: { type: String },
    roleFilter: { type: Array }
  },
})
export default class extends Vue {
  title = "";
  formData: UserForm = {
    id: "",
    displayName: "",
    email: "",
    roleIds: [],
    isEnabled: true,
    password: null,
    confirmPassword: null,
  };
  rolesDdl: RoleListViewModel[] = [];
  passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(.{8,})$/;

  get queries() {
    return {
      pageNumber: this.$props?.pageNumber,
      pageSize: this.$props?.pageSize,
      search: this.$props?.search,
      roleFilter: this.$props?.roleFilter,
    }
  }

  async created() {
    this.rolesDdl = await rolesDdlGet();
    this.loadInitialData(this.$props.id);
  }

  validatePassword = (rule: any, value: any, callback: any) => {
    if(!this.formData.id && !value) {
      callback(new Error("Please enter a password"));
    } else if (this.formData.id && !value) {
      callback();
    } else 
    if (!this.passwordRegex.test(value)) {
      callback(new Error("Minimum 8 characters: 1 uppercase, 1 lowercase, 1 symbol and 1 number"));
    } else {
      if (this.formData.confirmPassword !== "") {
        if (!this.$refs.formData) return;
        (this.$refs.formData as any).validateField("confirmPassword", () => null);
      }
      callback();
    }
  };

  validatePassword2 = (rule: any, value: any, callback: any) => {
    if(!this.formData.id && !value) {
      callback(new Error("Please confirm the password"));
    } else if (this.formData.id && !this.formData.password) {
      callback();
    } else if (value !== this.formData.password) {
      callback(new Error("The passwords do not match"));
    } else {
      callback();
    }
  };

  get rules(): Record<string, Rule> {
    return {
      email: { required: true, type: "email", message: "Please enter a valid email" },
      password: [{ required: this.formData.id ? false : true, validator: this.validatePassword }],
      confirmPassword: [{ required: this.formData.id ? false : true, validator: this.validatePassword2 }],
    }
  }

  submitForm(formName: string) {
    (this.$refs[formName] as any)?.validate((valid: boolean, fields: ValidateFieldsError) => {
      if (valid) {
        if(!this.formData.password) {
          this.formData.password = this.formData.confirmPassword = null;
        }
        this.formData.displayName = this.formData.email;

        if(!this.formData.id) {
          usersPost(this.formData as CreateUsersCommand)
          .then(async (res) => {
            this.formData.password = null;
            this.formData.confirmPassword = null;
            this.$router.replace({ name: "UserDetail", params: { id: res }});
            await this.loadInitialData(res);
          })
        } else {
          usersPut(this.formData.id, this.formData as UpdateUsersCommand)
          .then(() => {
            this.formData.password = null;
            this.formData.confirmPassword = null;
          })
        }
      } else {
        handleInvalidForm(fields as ValidateFieldsError);
      }
    });
  }

  async loadInitialData(id: string) {
    if (id) {
      await usersGetId(id)
        .then(async (res) => {
          this.title = res.email;
          this.formData.id = res.id as string;
          this.formData.displayName = res.displayName as string;
          this.formData.email = res.email as string;
          this.formData.roleIds = res.roleIds as [];
          this.formData.isEnabled = res.isEnabled as boolean;
        })
    }
  }
}
