import { CreateUsersCommand, PagedResultOfUserViewModelDto, RoleListViewModel, UpdateUsersCommand, UserViewModelDto } from "@/api-client";
import { Roles, Users } from "@/network/api";
import { handleError } from "@/utils/auth";
import { Message } from "element-ui";

export function usersGet(
  pgNum: number = 1,
  pgSize: number = 20,
  searchTerm: string | undefined = undefined,
  roleFilter: string[] | undefined = undefined,
  ): Promise<PagedResultOfUserViewModelDto> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Users.apiUsersGet(pgNum, pgSize, searchTerm, roleFilter)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function usersGetId(id: string): Promise<UserViewModelDto> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Users.apiUsersIdGet(id)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function usersPost(item: CreateUsersCommand): Promise<string> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Users.apiUsersPost(item)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        Message.success({ message: "Created!", showClose: true })
        resolve(res.data.value as string)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function usersPut(id: string, item: UpdateUsersCommand): Promise<void> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Users.apiUsersIdPut(id, item)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        Message.success({ message: "Updated!", showClose: true })
        resolve()
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function rolesDdlGet(): Promise<RoleListViewModel[]> {
  return new Promise((resolve, reject) => {
    Roles.apiRolesGet(1, 99999)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data.data as RoleListViewModel[])
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}